import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom"
import Media_1 from "../assets/img/header-media-1.webp"
import Media_2 from "../assets/img/header-media-2.webp"
import Media_3 from "../assets/img/header-media-3.webp"
import Media_4 from "../assets/img/header-media-4.webp"
import Media_5 from "../assets/img/header-media-5.webp"

import Capri from "../assets/img/capri.webp"
import Thawolo from "../assets/img/thawolo.webp"
import Degre from "../assets/img/360-degre.webp"

const Header = ({ header }) => {
    const navigate = useNavigate()
    const location = useLocation()

    const [Social, setSocial] = useState({})
    const [Sticky, setSticky] = useState(0)
    const [Active, setActive] = useState(false)
    const [Toggle, setToggle] = useState(false)
    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY >= 120) {
                setSticky(1)
            } else {
                setSticky(0)
            }
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.scroll({
                top: 0,
                left: 0,
            });
        };
    }, []);

    useEffect(() => {
        window.scroll({ top: 0, left: 0, });
    }, [location.pathname])
    const Modal = () => {
        return (
            <>
                <i className="fa-solid fa-chevron-down"></i>
                <div className="header-item-modal shadow-sm">
                    <div className="d-flex">
                        <div className="header-item-modal-item cursor-pointer" onClick={() => { window.location.href = `/menu/tagesmenu` }}>
                            <div className="img" style={{ backgroundImage: `url(${Media_1})` }}></div>
                            <div className="text-center text-white mt-2"> TAGESMENÜ</div>
                        </div>
                        <div className="header-item-modal-item cursor-pointer" onClick={() => { window.location.href = `/menu/speisekarte` }}>
                            <div className="img" style={{ backgroundImage: `url(${Media_2})` }}></div>
                            <div className="text-center text-white mt-2"> SPEISEKARTE</div>
                        </div>
                        <div className="header-item-modal-item cursor-pointer" onClick={() => { window.location.href = `/menu/desserts` }}>
                            <div className="img" style={{ backgroundImage: `url(${Media_3})` }}></div>
                            <div className="text-center text-white mt-2"> DESSERTS</div>
                        </div>
                        <div className="header-item-modal-item cursor-pointer" onClick={() => { window.location.href = `/menu/aktuell` }}>
                            <div className="img" style={{ backgroundImage: `url(${Media_4})` }}></div>
                            <div className="text-center text-white mt-2"> AKTUELL</div>
                        </div>
                        <div className="header-item-modal-item cursor-pointer" onClick={() => { window.location.href = `/menu/bankett` }}>
                            <div className="img" style={{ backgroundImage: `url(${Media_5})` }}></div>
                            <div className="text-center text-white mt-2"> BANKETT</div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
    const Scroll = () => {
        useEffect(() => {
            function handleScroll() {
                var winScroll = document.body.scrollTop || document.documentElement.scrollTop;
                var height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
                var scrolled = (winScroll / height) * 100;

                document.getElementById("progressBar").style.width = scrolled + "%";
            }
            window.addEventListener('scroll', handleScroll);
            return () => {
                window.removeEventListener('scroll', handleScroll);
            };
        }, []);

        return (
            <div className="progress-bar" id="progressBar" style={{ background: '#7f8367', height: '5px', width: '0%', position: 'fixed', zIndex: 1040 }}>
            </div>
        );
    }
    const [Data, setData] = useState({
        logo_white: "",
        logo_color: "",
        favico: "",
        facebook: "",
        instagram: ""
    })
    const Load = async () => {
        const result_info = await axios.get("https://ristorante-olivo.ch/api/api.php?params=select&table=kp_build&conditions=company_id='20242118'")
        //const result_social = await axios.get("https://ristorante-olivo.ch/api/api.php?params=select&table=kp_build_social&conditions=company_id='20242118'")
        if (result_info.data.length > 0) {
            setData({
                logo_white: JSON.parse(result_info.data[0]["logo_white"])["blob"],
                logo_color: JSON.parse(result_info.data[0]["logo_color"])["blob"],
                favico: "",
                facebook: "",
                instagram: ""
            })
        }
    }
    useEffect(() => {
        Load()
    }, [])


    return (
        <>
            <div className={`top ${Sticky === 1 ? "sticky" : ""}`}>
                <div className="container-inner">
                    <div className="d-flex">
                        <div className="top-menu-start">
                            <i className="fa-brands fa-facebook fs-2 text-white me-2 cursor-pointer" onClick={() => { window.open(`https://www.facebook.com/profile.php?id=100064681705058`, "blank") }}></i>
                            <i className="fa-brands fa-square-instagram fs-2 text-white cursor-pointer" onClick={() => { window.open(`https://www.instagram.com/ristorante.olivo/`, "blank") }}></i>
                        </div>
                        <div className="top-menu-end">
                            <div className="top-item-end cursor-pointer" onClick={() => { navigate(`/unsere-weine`) }}>Unsere Weine</div>
                            <div className="top-item-end cursor-pointer" onClick={() => { window.open(`https://www.pizzapp.ch/pizza/`, "blank") }}>Hauslieferung</div>
                            <div className="top-item-end cursor-pointer" onClick={() => { navigate(`/feiern`) }}>Feiern</div>
                        </div>
                    </div>
                </div>
            </div>
            <header className={`header ${Sticky === 1 ? "sticky" : ""}`}>
                <div className="container-inner position-relative">
                    <div className="d-flex">
                        <div className="header-menu-start">
                            <div className="header-item-start" onClick={() => { navigate(`/`) }}>HOME</div>
                            <div className="header-item-start">MENÜ
                                <Modal />
                            </div>
                            <div className="header-item-start" onClick={() => { navigate(`/tisch-reservation`) }}>TISCH RESERVATION</div>
                            <div className="header-item-start" onClick={() => { navigate(`/galerie`) }}>GALERIE</div>
                        </div>
                        <div className="header-logo cursor-pointer">
                            <img src={`https://ristorante-olivo.ch/api/upload/${Data.logo_color}`} onClick={() => { navigate(`/`) }} />
                        </div>
                        <div className="header-menu-end">
                            <div className="header-item-end" onClick={() => { window.open(`https://www.pizzapp.ch/pizza/`, "blank") }}>HAUSLIEFERUNG</div>
                            <div className="header-item-end" onClick={() => { navigate(`/virtual-tour`) }}>VIRTUAL TOUR</div>
                            <div className="header-item-end" onClick={() => { navigate(`/jobs`) }}>JOBS</div>
                            <div className="header-item-end" onClick={() => { navigate(`/kontakt`) }}>KONTAKT</div>
                        </div>
                        <div className="mobile-logo cursor-pointer" onClick={() => { navigate(`/`) }}>
                            <img src={`https://ristorante-olivo.ch/api/upload/${Data.logo_white}`} className="logo-image" />
                        </div>
                        <div className="mobile-menu">
                            <i className="fa-solid fa-bars" onClick={() => { setActive(true) }}></i>
                        </div>
                    </div>
                </div>
                <Scroll />
            </header>

            <div className={`drawer ${Active ? "active" : ""} shadow-sm`} style={{ backgroundImage: `url(${header.url})` }}>
                <div className="drawer-header">
                    <div className="w-100 p-2">
                        <img src={`https://www.thawolo.ch/api/upload/${Data.logo_white}`} className="logo-image" onClick={() => { navigate(`/`) }} />
                    </div>
                    <div className="w-100 p-2 d-flex justify-content-end">
                        <i className="fa-solid fa-circle-xmark text-white fs-2hx mr-20px" onClick={() => { setActive(false) }}></i>
                    </div>
                </div>
                <div className="drawer-body">
                    <div className="drawer-body-item" onClick={() => { window.location.href = "/" }}>HOME</div>
                    <div className="drawer-body-item" onClick={() => { Toggle ? setToggle(false) : setToggle(true) }}>
                        <div className="w-100">MENÜ</div>
                        <div className="w-100 d-flex justify-content-end me-2">
                            <i className="fa-solid fa-chevron-down fs-1"></i>
                        </div>
                    </div>
                    <div className={`drawer-body-item-toggle ${Toggle ? "active" : ""}`}>
                        <div className="drawer-body-item" onClick={() => { window.location.href = "/menu/tagesmenu" }}>
                            <i className="fa-solid fa-circle-dot me-2 fs-0hx"></i>
                            TAGESMENÜ
                        </div>
                        <div className="drawer-body-item" onClick={() => { window.location.href = "/menu/speisekarte" }}>
                            <i className="fa-solid fa-circle-dot me-2 fs-0hx"></i>
                            SPEISEKARTE
                        </div>
                        <div className="drawer-body-item" onClick={() => { window.location.href = "/menu/desserts" }}>
                            <i className="fa-solid fa-circle-dot me-2 fs-0hx"></i>
                            DESSERTS
                        </div>
                        <div className="drawer-body-item" onClick={() => { window.location.href = "/menu/aktuell" }}>
                            <i className="fa-solid fa-circle-dot me-2 fs-0hx"></i>
                            AKTUELL
                        </div>
                        <div className="drawer-body-item" onClick={() => { window.location.href = "/menu/bankett" }}>
                            <i className="fa-solid fa-circle-dot me-2 fs-0hx"></i>
                            BANKETT
                        </div>
                    </div>
                    <div className="drawer-body-item" onClick={() => { window.location.href = "/unsere-weine" }}>UNSERE WEINE</div>
                    <div className="drawer-body-item" onClick={() => { window.location.href = "/tisch-reservation" }}>TISCH RESERVATION</div>
                    <div className="drawer-body-item" onClick={() => { window.open(`https://www.pizzapp.ch/pizza/`, "blank") }}>HAUSLIEFERUNG</div>
                    <div className="drawer-body-item" onClick={() => { window.location.href = "/feiern" }}>FEIERN</div>
                    <div className="drawer-body-item" onClick={() => { window.location.href = "/galerie" }}>GALERIE</div>
                    <div className="drawer-body-item" onClick={() => { window.location.href = "/virtual-tour" }}>VIRTUAL TOUR</div>
                    <div className="drawer-body-item" onClick={() => { window.location.href = "/kontakt" }}>KONTAKT</div>
                    <div className="drawer-body-item" onClick={() => { window.location.href = "/jobs" }}>JOBS</div>
                </div>
                <div className="drawer-footer">
                    <div className="drawer-footer-item">info@ristorante-olivo.ch</div>
                    <div className="drawer-footer-item">044 534 99 99</div>
                    <div className="drawer-footer-social">
                        <i className="fa-brands fa-facebook fs-1hx text-white me-2 cursor-pointer" onClick={() => { window.open(`https://www.facebook.com/profile.php?id=100064681705058`, "blank") }}></i>
                        <i className="fa-brands fa-square-instagram fs-1hx text-white cursor-pointer" onClick={() => { window.open(`https://www.instagram.com/ristorante.olivo/`, "blank") }}></i>
                    </div>
                </div>
            </div>
            <div className={`drawer-overlay ${Active ? "active" : ""}`} onClick={() => { setActive(false) }}></div>
            <div className="desktop-header-empty"></div>
        </>
    )
}
const Footer = ({ footer }) => {
    const navigate = useNavigate()
    return (
        <footer className="footer" style={{ backgroundImage: `url(${footer.url})` }}>
            <div className="container-inner">
                <div className="footer-inner md-pt-5 md-pb-5">
                    <div className="w-100">
                        <div className="row">
                            <div className="col-lg-3 md-pb-3">
                                <div className="footer-title mb-2 ms-1">HIER FINDEN SIE UNS</div>

                                <div className="footer-text">
                                    <div className="d-flex">
                                        <div className="w-30px d-flex justify-content-center">
                                            <i className="fa-solid fa-location-dot mt-1"></i>
                                        </div>
                                        <div className="w-100 ms-2">
                                            Ristorante Olivo <br />
                                            Bahnhofstrasse 4 <br />
                                            8810 Horgen
                                        </div>
                                    </div>
                                </div>
                                <div className="footer-text">
                                    <div className="d-flex">
                                        <div className="w-30px d-flex justify-content-center">
                                            <i className="fa-solid fa-envelope mt-1"></i>
                                        </div>
                                        <div className="w-100 ms-2">
                                            info@ristorante-olivo.ch
                                        </div>
                                    </div>
                                </div>
                                <div className="footer-text">
                                    <div className="d-flex">
                                        <div className="w-30px d-flex justify-content-center">
                                            <i className="fa-solid fa-phone mt-1"></i>
                                        </div>
                                        <div className="w-100 ms-2">
                                            044 534 99 99
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 md-pb-3">
                                <div className="footer-title mb-2 ms-1">ÖFFNUNGSZEITEN</div>
                                <div className="footer-text">
                                    <div className="d-flex">
                                        <div className="w-30px d-flex justify-content-center">
                                            <i className="fa-solid fa-location-dot mt-1"></i>
                                        </div>
                                        <div className="w-100 ms-2">
                                            Wir haben 365 Tage offen
                                        </div>
                                    </div>
                                </div>
                                <div className="footer-text">
                                    <div className="d-flex">
                                        <div className="w-30px d-flex justify-content-center">
                                            <i className="fa-solid fa-clock mt-1"></i>
                                        </div>
                                        <div className="w-100 ms-2">
                                            Mo-Fr: 06:00-00:00 Uhr
                                        </div>
                                    </div>
                                </div>
                                <div className="footer-text">
                                    <div className="d-flex">
                                        <div className="w-30px d-flex justify-content-center">
                                            <i className="fa-solid fa-clock mt-1"></i>
                                        </div>
                                        <div className="w-100 ms-2">
                                            Sa: 07:00-00:00 Uhr
                                        </div>
                                    </div>
                                </div>
                                <div className="footer-text">
                                    <div className="d-flex">
                                        <div className="w-30px d-flex justify-content-center">
                                            <i className="fa-solid fa-clock mt-1"></i>
                                        </div>
                                        <div className="w-100 ms-2">
                                            Sonntag & Feiertage <br />
                                            08:00-00:00 Uhr
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 md-pb-3">
                                <div className="footer-title mb-2 ms-1">VIRTUAL TOUR</div>
                                <img src={Degre} onClick={() => { navigate(`/virtual-tour`) }} className="image cursor-pointer" />
                            </div>
                            <div className="col-lg-3 md-pb-3">
                                <div className="footer-title mb-2 ms-1">FILIALEN</div>
                                <img src={Thawolo} onClick={() => { window.open(`https://www.thawolo.ch/`) }} className="image mb-2 cursor-pointer" />
                                <img src={Capri} onClick={() => { window.open(`https://capri-adliswil.ch/`) }} className="image cursor-pointer" />
                            </div>
                            <div className="col-lg-12 md-pb-5 md-pt-1" style={{ textAlign: "start" }}>
                                <div className="text-white">© Copyright Designed <a href="https://kompassgroup.ch/">Kompass Group GmbH</a></div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </footer>
    )
}
const Section = ({ section, children }) => {
    switch (section.type) {
        case "content": return (
            <section className="section-content" style={{ backgroundImage: `url(${section.url})` }}>
                <div className={section.classname}>
                    {children}
                </div>
            </section>
        )
        case "video": return (
            <section className="section-video" style={{ backgroundImage: `url(${section.url})` }}>
                <div className={section.classname}>
                    {children}
                </div>
            </section>
        )
        case "form": return (
            <section className="section-form" style={{ backgroundImage: `url(${section.url})` }}>
                <div className={section.classname}>
                    {children}
                </div>
            </section>
        )
        case "banner": return (
            <div className="banner align-items-end" style={{ backgroundImage: `url(${section.url})` }}>
                {children}
            </div>
        )
    }
}
const Upload = ({ upload }) => {
    const clickRef = useRef()
    const [Data, setData] = useState("")
    const Select = (e) => {
        e.preventDefault();
        const [file] = e.target.files;
        setData(file.name)
        upload.response(file)
        if (clickRef.current) {
            clickRef.current.value = "";
        }
    }

    return (
        <>

            <div className="upload-container shadow-sm" onClick={() => { clickRef.current.click() }}>
                {
                    Data === "" ?
                        <div>
                            <div className="text-center"><i className="fa-solid fa-cloud-arrow-up"></i></div>
                            <div className="text-center">Datei auswählen...</div>
                        </div>
                        :
                        <div>
                            {Data}
                        </div>
                }

            </div>
            <input type="file" ref={clickRef} onChange={Select} hidden />

        </>
    )
}
const Dater = ({ calendar }) => {
    const today = new Date();
    const [currentMonth, setCurrentMonth] = useState(today.getMonth());
    const [currentYear, setCurrentYear] = useState(today.getFullYear());

    const days = ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"];
    const mounths = ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"];

    const handleTodayClick = () => {
        const today = new Date();
        setCurrentMonth(today.getMonth());
        setCurrentYear(today.getFullYear());
    };

    const next = () => {
        setCurrentYear(currentMonth === 11 ? currentYear + 1 : currentYear);
        setCurrentMonth((currentMonth + 1) % 12);
    };

    const previous = () => {
        setCurrentYear(currentMonth === 0 ? currentYear - 1 : currentYear);
        setCurrentMonth(currentMonth === 0 ? 11 : currentMonth - 1);
    };

    const daysInMonth = (iMonth, iYear) => {
        return 32 - new Date(iYear, iMonth, 32).getDate();
    };

    const getDaysOfWeek = () => {
        const startDayIndex = 0; // Pazartesi
        return days.slice(startDayIndex).concat(days.slice(0, startDayIndex));
    };

    const showCalendar = (month, year) => {
        const firstDay = new Date(year, month, 1).getDay();
        const daysOfWeek = getDaysOfWeek();
        const daysInCurrentMonth = daysInMonth(month, year);

        const NumberOfControl = (e) => {
            if (e < 10) {
                return "0" + e;
            } else {
                return e;
            }
        };

        const control = (d, m, y) => {

            if (y === today.getFullYear()) {
                if (m < (today.getMonth() + 1)) {
                    return "disable";
                }
                else if (m === (today.getMonth() + 1)) {
                    if (d < (today.getDate())) {
                        return "disable";
                    }
                    else {
                        return ""
                    }
                } else {
                    return ""
                }
            }
        }

        const SelectControl = (date) => {
            const today = new Date();  // Bugünün tarihi
            const currentYear = today.getFullYear();
            const currentMonth = today.getMonth();  // 0-11 arası, yani Ocak = 0, Aralık = 11
            const currentDate = today.getDate();  

            if (year > currentYear) {
                return true;  // Eğer yıl daha büyükse, kesinlikle seçilebilir
            } else if (year === currentYear) {
                if (month > currentMonth) {
                    return true;  // Eğer yıl aynıysa ve ay daha büyükse, kesinlikle seçilebilir
                } else if (month === currentMonth) {
                    if (date >= currentDate) {
                        return true;  // Eğer ay aynıysa ve gün bugünden büyükse, seçilebilir
                    }
                }
            }
            return false; 
        }

        const calendarBody = Array.from({ length: 6 }, (_, rowIndex) => {
            const row = Array.from({ length: 7 }, (_, columnIndex) => {
                const dayIndex = rowIndex * 7 + columnIndex;
                const date = dayIndex - firstDay + 1;

                if (rowIndex === 0 && columnIndex < firstDay) {
                    const prevMonthDate = daysInMonth(month === 0 ? 11 : month - 1, month === 0 ? year - 1 : year) - firstDay + columnIndex + 1;
                    return (
                        <td key={`empty-${columnIndex}`} className={SelectControl(date) ? "" : "old-date"}>
                            <div className="d-flex align-items-center justify-content-center shadow-sm fs-14 cursor-pointer" style={{ color: "#6c5037", height: "40px" }}>
                                {prevMonthDate}
                            </div>
                        </td>
                    );
                } else if (date > daysInCurrentMonth) {
                    const nextMonthDate = date - daysInCurrentMonth;
                    return (
                        <td key={`empty-${columnIndex}`} className={SelectControl(date) ? "next-date" : "old-date"}>
                            <div
                                onClick={() => { SelectControl(date) && calendar.response({ year: year, month: NumberOfControl(currentMonth + 2), day: NumberOfControl(nextMonthDate) }) }}
                                className="d-flex align-items-center justify-content-center shadow-sm fs-14 cursor-pointer" style={{ color: "#6c5037", height: "40px" }}>
                                {nextMonthDate}
                            </div>
                        </td>
                    );
                } else {
                    return (
                        <td key={date} className={SelectControl(date) ? "current-date" : "old-date"}>
                            <div
                                onClick={() => { SelectControl(date) && calendar.response({ year: year, month: NumberOfControl(month + 1), day: NumberOfControl(date) }) }}
                                className="d-flex align-items-center justify-content-center shadow-sm fs-14 cursor-pointer"
                                style={{
                                    height: "40px",
                                    padding: "2px",
                                    backgroundColor: `${today.getDate() === date && year === today.getFullYear() && month === today.getMonth() ? "#6c5037" : "#fff"}`,
                                    color: `${today.getDate() === date && year === today.getFullYear() && month === today.getMonth() ? "#fff" : "#555"}`,
                                    cursor: "pointer"
                                }}>
                                {control(date, NumberOfControl(month + 1), year) === "disable" ?
                                    <div style={{ opacity: 0.9, pointerEvents: "none" }}>{date}</div>
                                    :
                                    <div>{date}</div>}
                            </div>
                        </td>
                    );
                }
            });
            return <tr key={rowIndex}>{row}</tr>;
        });

        return calendarBody;
    };

    return (
        <>
            <div className="row">
                <div className="col-lg-12 p-0">
                    <div className="d-flex">
                        <div className="w-50px">
                            <div className="btn-sm d-flex align-items-center justify-content-center cursor-pointer" style={{ height: "30px", width: "30px", borderRadius: "0", backgroundColor: "#6c5037" }} onClick={previous}>
                                <i class="fa-solid fa-chevron-left text-white"></i>
                            </div>
                        </div>
                        <div className="w-100 d-flex justify-content-center" style={{ borderRight: "solid 1px #fff", borderLeft: "solid 1px #fff" }}>
                            <div className="btn-sm d-flex align-items-center justify-content-center cursor-pointer" style={{ height: "30px", width: "100%", borderRadius: "0", backgroundColor: "#6c5037" }} onClick={handleTodayClick}>
                                <div className="text-white">{currentYear}</div>
                            </div>
                        </div>
                        <div className="w-100 d-flex justify-content-center" style={{ borderRight: "solid 1px #fff" }}>
                            <div className=" btn-sm d-flex align-items-center justify-content-center cursor-pointer" style={{ height: "30px", width: "100%", borderRadius: "0", backgroundColor: "#6c5037" }} onClick={handleTodayClick}>
                                <div className="text-white">Heute</div>
                            </div>
                        </div>
                        <div className="w-100 d-flex justify-content-center" style={{ borderRight: "solid 1px #fff" }}>
                            <div className="btn-sm d-flex align-items-center justify-content-center cursor-pointer" style={{ height: "30px", width: "100%", borderRadius: "0", backgroundColor: "#6c5037" }} onClick={handleTodayClick}>
                                <div className="text-white">{mounths[currentMonth]}</div>
                            </div>
                        </div>
                        <div className="w-50px d-flex justify-content-end">
                            <div className="btn-sm d-flex align-items-center justify-content-center cursor-pointer" style={{ height: "30px", width: "30px", borderRadius: "0", backgroundColor: "#6c5037" }} onClick={next}>
                                <i class="fa-solid fa-chevron-right text-white"></i>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-12 p-0">
                    <table className="shadow" style={{ width: "100%", backgroundColor: "#fff" }}>
                        <thead>
                            <tr>
                                {days.map((day, index) => (<th key={index} className="test2">{day}</th>))}
                            </tr>
                        </thead>
                        <tbody id="calendar-body">
                            {showCalendar(currentMonth, currentYear)}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
};
const Timer = ({ timer }) => {
    const [Active, setActive] = useState(false)
    const [Value, setValue] = useState("")
    const hour = [
        { id: 1100, time: "11:00-11:30" },
        { id: 1130, time: "11:30-12:00" },
        { id: 1330, time: "12:00-12:30" },
        { id: 1230, time: "12:30-13:00" },
        { id: 1300, time: "13:00-13:30" },
        { id: 1330, time: "13:30-14:00" },


        { id: 1700, time: "17:00-17:30" },
        { id: 1730, time: "17:30-18:00" },
        { id: 1800, time: "18:00-18:30" },
        { id: 1830, time: "18:30-19:00" },
        { id: 1900, time: "19:00-19:30" },
        { id: 1930, time: "19:30-20:00" },
        { id: 2000, time: "20:00-20:30" },
        { id: 2030, time: "20:30-21:00" },
        { id: 2100, time: "21:00-21:30" },
        { id: 2130, time: "21:30-22:00" },

    ]
    const control = () => {
        const data = new Date();
        const h = data.getHours();
        const m = data.getMinutes();
        return parseInt(h + "" + m);
    }

    const datecontrol = (d, m, y) => {
        const today = new Date();
        if (d === (today.getDate())) {
            return false;
        }
        else {
            return true
        }
    }

    return (
        <>
            <input className={`form-control h-50px shadow-sm cursor-pointer ${timer.classname}`} placeholder="Zeit auswählen *" value={Value} onClick={() => { setActive(true) }} />
            <div className="timer-container">
                <div className={`timer-modal ${Active ? "active" : ""} shadow-sm`}>
                    {
                        timer.selected &&
                        (hour || []).map((d, x) => {
                            return (
                                <div
                                    className={`timer-modal-item ${Value === d ? "active" : ""}  ${datecontrol(timer.selected.split(".")[0], timer.selected.split(".")[0], timer.selected.split(".")[0]) === false && d.id < control() ? "disable" : ""}`}
                                    onClick={() => {
                                        timer.response(d.time);
                                        setValue(d.time);
                                        setActive(false);
                                    }}>
                                    {d.time}
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </>
    )

}
const Calendar = ({ calendar }) => {
    const [Active, setActive] = useState(false)
    const [Value, setValue] = useState("")
    return (
        <>

            <input className={`form-control h-50px shadow-sm cursor-pointer ${calendar.classname}`} placeholder="Datum auswählen *" value={Value} onClick={() => { setActive(true) }} />
            <div className="calendar-modal-container">
                <div className={`calendar-modal shadow-sm ${Active ? "active" : ""}`}>
                    <Dater calendar={{
                        response: (e) => {
                            setValue(`${e["day"]}.${e["month"]}.${e["year"]}`);
                            calendar.response(`${e["day"]}.${e["month"]}.${e["year"]}`);
                            setActive(false)
                        }
                    }} />
                </div>
            </div>
            <div className={`calendar-modal-ovarlay ${Active ? "active" : ""}`} onClick={() => { setActive(false) }}></div>
        </>
    )
}

export default function Module({ module, header, footer, section, upload, calendar, timer, children }) {


    switch (module) {
        case "header": return <Header header={header} />
        case "footer": return <Footer footer={footer} />
        case "upload": return <Upload upload={upload} />
        case "calendar": return <Calendar calendar={calendar} />
        case "timer": return <Timer timer={timer} />
        case "section": return <Section section={section} children={children} />

        default: return <>No Select Module</>
    }
}

